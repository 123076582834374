import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { ShadowBox } from 'components/__styled/ShadowBox';
import {
  Summary,
  Header,
  SubHeader,
  SummaryItem,
  SummaryItemValue,
  SummaryItemLabel,
  SummaryItems,
  SummaryPart,
  SummaryContent,
  ContractWrapper,
  ContractSelect,
  ButtonWrapper
} from './styles';
import { Label } from 'components/__styled/Form';
import Select from 'components/__common/Select';
import { setSelectOption } from 'utils/form';
import Button from 'components/__common/Button';
import { formatToHoursAndMinutes } from 'utils/global';
import { displayToast } from 'utils/toast';
import ContractsService from 'services/ContractsService';
import { HiOutlinePlay } from 'react-icons/hi'
import { BsStopCircle } from 'react-icons/bs';
import theme from 'config/theme';
import UserContext from 'context/UserContext';

const MapSummary = ({ summary, contracts, currentContract, getData, setCurrentContract }) => {
  const { hasRoleAccess, user } = useContext(UserContext);
  const workStarted = summary?.workStatus;
  const [loading, setLoading] = useState(false);
  const updateLocationIntervalRef = useRef(null);

  const updateLocationSilently = useCallback(async () => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser!');
    } else {
      navigator.geolocation.getCurrentPosition(async position => {
        if (position && position.coords) {
          const { latitude, longitude } = position.coords;
          await ContractsService.updateWork(currentContract.value, { lat: latitude, lon: longitude, work: true });
        }
      },
      err => {
        console.log(err);
      });
    }
  }, [currentContract.value]);

  useEffect(() => {
    if (
      summary?.workStatus &&
      currentContract &&
      user?.id === summary.workUser
    ) {
      updateLocationIntervalRef.current = setInterval(async () => {
        updateLocationSilently();
      }, 60000);
    }
    if (localStorage.getItem('contract-map') &&
        JSON.parse(localStorage.getItem('contract-map')).value !== currentContract.value
    ) {
        setCurrentContract( JSON.parse(localStorage.getItem('contract-map')));
    }

    return () => {
      clearInterval(updateLocationIntervalRef.current);
    };
  }, [summary, currentContract, updateLocationSilently, user, setCurrentContract]);

  const callLocationUpdate = () => {
    setLoading(true);
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser!');
    } else {
      navigator.geolocation.getCurrentPosition(async position => {
        if (position && position.coords) {
          await updateWorkStatus(position.coords, !workStarted);
          setLoading(false);
        }
      },
      err => {
        setLoading(false);
        console.log(err);
      });
    }
  };

  const updateWorkStatus = async (coords, startWork = true) => {
    try {
      const { latitude, longitude } = coords;
      await ContractsService.updateWork(currentContract.value, { lat: latitude, lon: longitude, work: startWork });
      getData();
      displayToast('success', startWork ? 'Prace budowlane zostały rozpoczęte!' : 'Prace budowlane zostały na dziś zakończone!');
    } catch (err) {
      displayToast('error', 'Wystąpił nieoczekiwany błąd!');
      console.log(err);
    }
  };

  return (
    <Summary>
      <ShadowBox>
        <ContractWrapper>
          <ContractSelect>
            <Label>Wybrany kontrakt</Label>
            <Select
              name="contracts"
              options={contracts}
              onChange={option => {
                localStorage.removeItem('contract-map');
                localStorage.setItem('contract-map', JSON.stringify(option));
                setCurrentContract(option);
              }}
              value={currentContract && setSelectOption(contracts, currentContract.value)}
            />
          </ContractSelect>
        </ContractWrapper>
        <SummaryContent>
            {summary
            && summary.deliverySchedules
            && summary.deliverySchedules._embedded
            && summary.deliverySchedules._embedded.item
            && summary.deliverySchedules._embedded.item.length > 0 ?
                summary.deliverySchedules._embedded.item.map((row, i) => {
                  return (
                      <SummaryPart>
                        <Header>Zadanie {i + 1}</Header>
                        <SubHeader>{row ? row.location + "_" + row.subcategory + "_" + row.locationFrom + "-" + row.locationTo : '-'}</SubHeader>
                        <SummaryItems>
                          <SummaryItem>
                            <SummaryItemLabel>Godzina rozpoczęcia:</SummaryItemLabel>
                            <SummaryItemValue>{row ? row.startOfUnloadingCar.substr(0,5) : '-'}</SummaryItemValue>
                          </SummaryItem>
                          <SummaryItem>
                            <SummaryItemLabel>Planowana ilość ton do wbudowania:</SummaryItemLabel>
                            <SummaryItemValue>{row ? row.plannedDailyAmount : '-'}</SummaryItemValue>
                          </SummaryItem>
                          <SummaryItem>
                            <SummaryItemLabel>Liczba samochodów do podstawienia:</SummaryItemLabel>
                            <SummaryItemValue>{row ? row.numerOfCars : '-'}</SummaryItemValue>
                          </SummaryItem>
                          <SummaryItem>
                            <SummaryItemLabel>Liczba kursów dla samochodu:</SummaryItemLabel>
                            <SummaryItemValue>{row ? row.numerOfCourseForCar : '-'}</SummaryItemValue>
                          </SummaryItem>
                          <SummaryItem>
                            <SummaryItemLabel>Czas przejazdu:</SummaryItemLabel>
                            <SummaryItemValue>{row ? `${row.carCourseTimeH} g ${row.carCourseTimeMin} min` : '-'}</SummaryItemValue>
                          </SummaryItem>
                          <SummaryItem>
                            <SummaryItemLabel>Czas wbudowania bez przerw:</SummaryItemLabel>
                            <SummaryItemValue>{row ? formatToHoursAndMinutes(row.buildInTimeWithoutGaps) : '-'}</SummaryItemValue>
                          </SummaryItem>
                          <SummaryItem>
                            <SummaryItemLabel>Łączny czas wbudowania:</SummaryItemLabel>
                            <SummaryItemValue>{row ? formatToHoursAndMinutes(row.buildInTime) : '-'}</SummaryItemValue>
                          </SummaryItem>
                          <SummaryItem>
                            <SummaryItemLabel>Prędkość układania</SummaryItemLabel>
                            <SummaryItemValue>{row ? `${row.proposedPaverSpeed} m/min` : '-'}</SummaryItemValue>
                          </SummaryItem>
                        </SummaryItems>
                      </SummaryPart>
                )})
                    : (
                    <SummaryPart>
                      <Header>Zaplanowane zadanie</Header>
                      <SummaryItems>
                        <SummaryItem>
                          <SummaryItemLabel>Godzina rozpoczęcia:</SummaryItemLabel>
                          <SummaryItemValue>{summary && summary.deliverySchedule ? summary.deliverySchedule.startOfUnloadingCar.substr(0,5) : '-'}</SummaryItemValue>
                        </SummaryItem>
                        <SummaryItem>
                          <SummaryItemLabel>Planowana ilość ton do wbudowania:</SummaryItemLabel>
                          <SummaryItemValue>{summary && summary.deliverySchedule ? summary.deliverySchedule.plannedDailyAmount : '-'}</SummaryItemValue>
                        </SummaryItem>
                        <SummaryItem>
                          <SummaryItemLabel>Liczba samochodów do podstawienia:</SummaryItemLabel>
                          <SummaryItemValue>{summary && summary.deliverySchedule ? summary.deliverySchedule.numerOfCars : '-'}</SummaryItemValue>
                        </SummaryItem>
                        <SummaryItem>
                          <SummaryItemLabel>Liczba kursów dla samochodu:</SummaryItemLabel>
                          <SummaryItemValue>{summary && summary.deliverySchedule ? summary.deliverySchedule.numerOfCourseForCar : '-'}</SummaryItemValue>
                        </SummaryItem>
                        <SummaryItem>
                          <SummaryItemLabel>Czas przejazdu:</SummaryItemLabel>
                          <SummaryItemValue>{summary && summary.deliverySchedule ? `${summary.deliverySchedule.carCourseTimeH} g ${summary.deliverySchedule.carCourseTimeMin} min` : '-'}</SummaryItemValue>
                        </SummaryItem>
                        <SummaryItem>
                          <SummaryItemLabel>Czas wbudowania bez przerw:</SummaryItemLabel>
                          <SummaryItemValue>{summary && summary.deliverySchedule ? formatToHoursAndMinutes(summary.deliverySchedule.buildInTimeWithoutGaps) : '-'}</SummaryItemValue>
                        </SummaryItem>
                        <SummaryItem>
                          <SummaryItemLabel>Łączny czas wbudowania:</SummaryItemLabel>
                          <SummaryItemValue>{summary && summary.deliverySchedule ? formatToHoursAndMinutes(summary.deliverySchedule.buildInTime) : '-'}</SummaryItemValue>
                        </SummaryItem>
                        <SummaryItem>
                          <SummaryItemLabel>Prędkość układania</SummaryItemLabel>
                          <SummaryItemValue>{summary && summary.deliverySchedule ? `${summary.deliverySchedule.proposedPaverSpeed} m/min` : '-'}</SummaryItemValue>
                        </SummaryItem>
                      </SummaryItems>
                    </SummaryPart>
                )
            }
        </SummaryContent>
      </ShadowBox>
    </Summary>
  );
}

export default MapSummary;
