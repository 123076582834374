import React, { useState, useEffect, useCallback } from 'react';
import {
  MapWrapper,
  MapFlexWrapper,
  MainWrapper,
  MapLegend,
  MapLegendItem,
  LegendItemColor,
  LegendItemValue,
  MapLegendHeader,
  MapLegendContent, MapOuterWrapper,
} from './styles';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
import Marker from 'react-leaflet-enhanced-marker'
import 'leaflet/dist/leaflet.css';
import Header from 'components/__common/Header';
import { Subtitle } from 'components/__styled/Subtitle';
import MapSummary from 'components/Map/MapSummary';
import ContractsService from 'services/ContractsService';
import { AnimatePresence } from 'framer-motion';
import PageLoader from '../../components/PageLoader';
import Fade from '../../components/Fade';
import LiveSummary from 'components/Map/LiveSummary';
import VehicleMarker from 'components/Map/VehicleMarker';
import PaverMarker from 'components/Map/PaverMarker';
import { COLORS } from 'config/theme';
import WMBMarker from 'components/Map/WMBMarker';

const Map = () => {
  const [map, setMap] = useState(null);
  const [carsLoading, setCarsLoading] = useState(true);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [currentContract, setCurrentContract] = useState(null);
  const [carsPositions, setCarsPositions] = useState([]);
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    setCarsPositions([]);
    setSummary(null);

    if (currentContract) {
      getData(currentContract.value, true);
    }

    const carsPositionsInterval = setInterval(() => {
      if (currentContract) {
        getData(currentContract.value, false);
      }
    }, 60000);

    return () => clearInterval(carsPositionsInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContract]);

  useEffect(() => {
    let bounds = [];
    carsPositions.forEach(m => {
      if (m.location) {
        bounds = [...bounds, [m.location.lat, m.location.lon]];
      }
    });

    if (summary?.geoZone) {
      bounds = [...bounds, [summary.geoZone.points[0].latitude, summary.geoZone.points[0].longitude]];
    }

    if (map && bounds.length > 0) {
      map.fitBounds(bounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, carsPositions, summary]);

  const renderMarker = data => {
    return data.type === 'CAR' ? <VehicleMarker data={data} /> : <PaverMarker data={data} />;
  };

  const getContracts = async () => {
    const res = await ContractsService.getContracts({ itemsPerPage: 99999 });
    const options = res.data._embedded ? res.data._embedded.item.map(contract => ({ label: contract.name, value: contract.id })) : [];
    setContracts(options);
    setCurrentContract(options[0]);
    return options;
  };

  const getCarsPositions = async (id, withLoader = true) => {
    try {
      withLoader && setCarsLoading(true);
      const res = await ContractsService.getCarsPositions(id);
      setCarsPositions(res.data._embedded.item);
      withLoader && setCarsLoading(false);
    } catch (err) {
      withLoader && setCarsLoading(false);
    }
  };

  const getScheduleSummary = async (id, withLoader = true) => {
    try {
      withLoader && setSummaryLoading(true);
      const res = await ContractsService.getScheduleSummary(id);
      setSummary(res.data);
      withLoader && setSummaryLoading(false);
    } catch (err) {
      withLoader && setSummaryLoading(false);
    }
  };

  const getData = useCallback(async (id, withLoader) => {
    getCarsPositions(id, withLoader);
    getScheduleSummary(id, withLoader);
  }, []);

  useEffect(() => {
    const initialGet = async () => {
      const contracts = await getContracts();
      if (contracts.length > 0) {
        setCurrentContract(contracts[0]);
      } else {
        setCarsLoading(false);
        setSummaryLoading(false);
      }
    }

    initialGet();
  }, [getData]);

  const geoZone = summary?.geoZone?.points.map(point => {
    return [point.latitude, point.longitude];
  });

  return (
    <AnimatePresence>
      {carsLoading && summaryLoading
        ? <PageLoader/>
        : (
          <Fade>
            <div>
              <Header size={23} beFlex>Śledzenie pojazdów</Header>
              <Subtitle>Śledź pozycję pojazdów w zależności od kontraktu w czasie rzeczywistym.</Subtitle>
            </div>
            <LiveSummary summary={summary}/>
            <MainWrapper>
              <MapFlexWrapper>
                <MapOuterWrapper>
                  <MapWrapper>
                    <MapContainer
                      center={[51.984876, 19.601698]}
                      zoom={5}
                      scrollWheelZoom={true}
                      whenCreated={m => setMap(m)}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {geoZone?.length > 0 && <Polygon color={COLORS.dark} positions={geoZone} />}
                      {geoZone?.length > 0 && <Marker position={geoZone[0]} icon={<WMBMarker />} />}
                      {carsPositions.map((m, i) => {
                        return (
                          <React.Fragment key={i}>
                            {m.location && <Marker position={[m.location.lat, m.location.lon]} icon={renderMarker(m)} />}
                          </React.Fragment>
                        );
                      })}
                    </MapContainer>
                  </MapWrapper>
                  <MapLegend>
                    <MapLegendHeader>Legenda dla pojazdów:</MapLegendHeader>
                    <MapLegendContent>
                      <MapLegendItem>
                        <LegendItemColor color={COLORS.main} />
                        <LegendItemValue>Pojazd załadowany</LegendItemValue>
                      </MapLegendItem>
                      <MapLegendItem>
                        <LegendItemColor color={'#73727282'}/>
                        <LegendItemValue>Pojazd po rozładunku</LegendItemValue>
                      </MapLegendItem>
                    </MapLegendContent>
                  </MapLegend>
                </MapOuterWrapper>
                <MapSummary
                  getData={() => getData(currentContract.value, true)}
                  contracts={contracts}
                  summary={summary}
                  currentContract={currentContract}
                  setCurrentContract={setCurrentContract}
                />
              </MapFlexWrapper>
            </MainWrapper>
          </Fade>
        )
      }
    </AnimatePresence>
  );
};

export default Map;
