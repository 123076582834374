import React from 'react';
import { COLORS, FONTS } from 'config/theme';

const PaverMarker = ({ data }) => {
  return (
    <div style={{position: 'relative', left: '32px'}}>
      <div style={{
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        backgroundColor: '#c36efb',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div style={{
          width: '19px',
          height: '19px',
          backgroundColor: '#c36efb',
          borderRadius: '50%',
          border: '2px solid white'
        }}/>
      </div>
      <div style={{
        position: 'absolute',
        left: '40px',
        top: '8px',
        borderRadius: '4px',
        backgroundColor: '#4d4d4d',
        padding: '3px 8px',
        color: `${COLORS.white}`,
        fontFamily: `${FONTS.semiBold}`,
        fontSize: '10px',
        width: '65px'
      }}>
        {data.name}
      </div>
    </div>
  );
};

export default PaverMarker;
